import { useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import SpreadsheetPicker from './SpreadsheetPicker';
import { Avatar, Flex, Button } from 'monday-ui-react-core';
import { mondayWorkbookToTransportFormat, BoardTransportFormat, transportFormatToWorkbook } from '../services/converter';
import monday from '../services/monday';
import { useMe } from '../hooks/use-me';
import ErrorBox from './ErrorBox';
// @ts-ignore-next-line
import RotateIcon from 'monday-ui-react-core/dist/icons/Rotate';

function ConverterWidget() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [transportFormat, setTransportFormat] = useState<BoardTransportFormat | null>(null);
  const { user, error: userError } = useMe();

  if (!user && !userError) {
    return null;
  }

  if (userError) {
    return (
      <div className="SuperConverter SuperConverter--app">
        <ErrorBox message={userError?.message || 'unknown error'} />
      </div>
    );
  }

  return (
    <div className="SuperConverter SuperConverter--app">
      {errorMessage ? <ErrorBox message={errorMessage} /> : null}

      <div className={errorMessage ? 'Main Error' : 'Main'}>
        <div className="Wrapper">
          <div className={transportFormat ? 'SpreadsheetPicker' : 'SpreadsheetPicker SpreadsheetPicker--Empty'}>
            <SpreadsheetPicker
              format={!!transportFormat}
              onChange={(workbook) => {
                try {
                  setErrorMessage(null);
                  setTransportFormat(workbook ? mondayWorkbookToTransportFormat(workbook) : null);
                } catch (err) {
                  setTransportFormat(null);
                  setErrorMessage((err as Error).message);
                }
              }}
            />
          </div>

          {transportFormat ? (
            <>
              <div className="DownloadPicker">
                <div className="DownloadPicker-items">
                  <div className={transportFormat ? 'DownloadPicker-item' : 'DownloadPicker-item disabled'}>
                    <div
                      className="DownloadPicker-itemInner"
                      onClick={() => {
                        const workbook = transportFormatToWorkbook(transportFormat);
                        XLSX.writeFile(workbook, `${transportFormat.name}.xlsx`, { compression: true });
                        monday.execute('valueCreatedForUser');
                      }}
                    >
                      <Avatar
                        withoutBorder
                        square
                        size={Avatar.sizes?.MEDIUM}
                        type={Avatar.types?.ICON}
                        icon={RotateIcon}
                        customBackgroundColor={transportFormat ? '#0073E3' : '#d0d4e4'}
                      />
                      <div>
                        <h4>Convert to XLSX</h4>
                        <label>without formatting</label>
                      </div>
                    </div>
                  </div>
                  <div className={transportFormat ? 'DownloadPicker-item' : 'DownloadPicker-item disabled'}>
                    <div
                      className="DownloadPicker-itemInner"
                      onClick={() => {
                        const workbook = transportFormatToWorkbook(transportFormat);
                        XLSX.writeFile(workbook, `${transportFormat.name}.ods`);
                        monday.execute('valueCreatedForUser');
                      }}
                    >
                      <Avatar
                        withoutBorder
                        square
                        size={Avatar.sizes?.MEDIUM}
                        type={Avatar.types?.ICON}
                        icon={RotateIcon}
                        customBackgroundColor={transportFormat ? '#0073E3' : '#d0d4e4'}
                      />
                      <div>
                        <h4>Convert to ODS</h4>
                        <label>without formatting</label>
                      </div>
                    </div>
                  </div>
                  <div className={transportFormat ? 'DownloadPicker-item' : 'DownloadPicker-item disabled'}>
                    <div
                      className="DownloadPicker-itemInner"
                      onClick={() => {
                        const workbook = transportFormatToWorkbook(transportFormat);
                        XLSX.writeFile(workbook, `${transportFormat.name}.html`);
                        monday.execute('valueCreatedForUser');
                      }}
                    >
                      <Avatar
                        withoutBorder
                        square
                        size={Avatar.sizes?.MEDIUM}
                        type={Avatar.types?.ICON}
                        icon={RotateIcon}
                        customBackgroundColor={transportFormat ? '#0073E3' : '#d0d4e4'}
                      />
                      <div>
                        <h4>Convert to HTML</h4>
                        <label>without formatting</label>
                      </div>
                    </div>
                  </div>
                  <div className={transportFormat ? 'DownloadPicker-item' : 'DownloadPicker-item disabled'}>
                    <div
                      className="DownloadPicker-itemInner"
                      onClick={() => {
                        const workbook = transportFormatToWorkbook(transportFormat);
                        XLSX.writeFile(workbook, `${transportFormat.name}.csv`);
                        monday.execute('valueCreatedForUser');
                      }}
                    >
                      <Avatar
                        withoutBorder
                        square
                        size={Avatar.sizes?.MEDIUM}
                        type={Avatar.types?.ICON}
                        icon={RotateIcon}
                        customBackgroundColor={transportFormat ? '#0073E3' : '#d0d4e4'}
                      />
                      <div>
                        <h4>Convert to CSV</h4>
                        <label>with items only</label>
                      </div>
                    </div>
                  </div>
                  {transportFormat.subitems ? (
                    <div className={transportFormat ? 'DownloadPicker-item' : 'DownloadPicker-item disabled'}>
                      <div
                        className="DownloadPicker-itemInner"
                        onClick={() => {
                          const workbook = transportFormatToWorkbook(transportFormat, false, true);
                          XLSX.writeFile(workbook, `${transportFormat.name} Subitems.csv`);
                          monday.execute('valueCreatedForUser');
                        }}
                      >
                        <Avatar
                          withoutBorder
                          square
                          size={Avatar.sizes?.MEDIUM}
                          type={Avatar.types?.ICON}
                          icon={RotateIcon}
                          customBackgroundColor={transportFormat ? '#0073E3' : '#d0d4e4'}
                        />
                        <div>
                          <h4>Convert to CSV</h4>
                          <label>with subitems only</label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="Teasers">
          <div className="Teaser--item">
            <Flex direction={Flex.directions?.COLUMN}>
              <h2>Supported format</h2>
              <p>
                The app supports Excel files from monday.com <br />
                board table views using the default format <br />
                from about 2020 that usually contains <br />
                "Powered by monday.com" in cell D4.
              </p>
            </Flex>
          </div>
          <div className="Teaser--item Teaser--itemGreen">
            <Flex direction={Flex.directions?.COLUMN}>
              <h2>
                Did you already try <br />
                Spreadsheet Gorilla?
              </h2>
              <p>
                Spreadsheet Gorilla is your all-in-one solution <br />
                for creating and exporting fully customized <br />
                spreadsheets directly on monday.com
              </p>
            </Flex>
            <Button
              size={Button.sizes?.MEDIUM}
              kind={Button.kinds?.PRIMARY}
              color={Button.colors?.ON_PRIMARY_COLOR}
              onClick={() => {
                //createSignal('open_spreadsheet_gorilla');
                window.open('https://monday.com/marketplace/10000256', '_blank');
              }}
            >
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConverterWidget;
