import { getSessionToken } from './monday';

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function getMe() {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/me`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
  });

  return await response.json();
}
