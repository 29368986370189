import * as _ from 'lodash';
import * as XLSX from 'xlsx-js-style';

const MONDAY_LOGO_FILE_PATH = 'xl/media/image1.png';
const MONDAY_LOGO_FILE_SIZE = 11186;

type CellTransportFormat = {
  value: any;
  original: XLSX.CellObject | undefined;
};

type ItemTransportFormat = {
  group: string;
  name: string;
  cells: CellTransportFormat[];
};

type SubitemTransportFormat = ItemTransportFormat & {
  parentIdx: number;
};

export type BoardTransportFormat = {
  name: string;
  headlines: string[];
  subitemsHeadlines: string[] | null;
  items: ItemTransportFormat[];
  subitems: SubitemTransportFormat[] | null;
};

function getRowValues(mondaySheet: XLSX.WorkSheet, startColumn = 0, row = 2) {
  const values = [];
  let currentColumn = startColumn;

  while (true) {
    const cell = mondaySheet[XLSX.utils.encode_cell({ c: currentColumn++, r: row })];

    if (!cell) {
      break;
    }

    values.push(cell.v);
  }

  return values;
}

export function getBoardName(mondayWorkbook: XLSX.WorkBook) {
  const mondaySheet = mondayWorkbook.Sheets[mondayWorkbook.SheetNames[0]];
  return mondaySheet['A1']?.v;
}

function rowToItemTransport(mondaySheet: XLSX.WorkSheet, groupName: string | null, startColumn: number, row: number, columnsCount: number) {
  const itemTransport: ItemTransportFormat = {
    group: groupName || '',
    name: '',
    cells: [],
  };

  for (let currentColumn = startColumn; currentColumn < startColumn + columnsCount; currentColumn++) {
    const cell = mondaySheet[XLSX.utils.encode_cell({ c: currentColumn, r: row })];

    if (currentColumn === startColumn) {
      itemTransport.name = cell?.v;
    }

    itemTransport.cells.push({
      value: cell?.v,
      original: cell,
    });
  }

  return itemTransport;
}

function hasFormulasInRow(mondaySheet: XLSX.WorkSheet, row: number) {
  const range = XLSX.utils.decode_range(mondaySheet['!ref']!);
  const columnsCount = range.e.c + 1;

  for (let currentColumn = 0; currentColumn < columnsCount; currentColumn++) {
    const cell = mondaySheet[XLSX.utils.encode_cell({ c: currentColumn, r: row })];
    if (cell?.f) {
      return true;
    }
  }

  return false;
}

export function getMondayWorkbookCharacteristics(mondayWorkbook: XLSX.WorkBook) {
  const mondaySheet = mondayWorkbook.Sheets[mondayWorkbook.SheetNames[0]];
  const mondayLogoFile = _.get(mondayWorkbook, ['files', MONDAY_LOGO_FILE_PATH]);
  const hasMondayLogo = !!(mondayLogoFile && mondayLogoFile.size === MONDAY_LOGO_FILE_SIZE);
  const hasBoardDescription = mondaySheet['A3']?.v === '';
  const firstGroupRowIdx = hasBoardDescription ? 3 : 1;
  const firstHeadlineCell = mondaySheet[XLSX.utils.encode_cell({ c: 0, r: firstGroupRowIdx + 1 })];
  const columnHeadlineIsGrey = _.get(firstHeadlineCell, 's.fgColor.rgb') === 'D6D6D6';

  return {
    hasMondayLogo: hasMondayLogo,
    hasBoardDescription: hasBoardDescription,
    columnHeadlineIsGrey: columnHeadlineIsGrey,
  };
}

export function mondayWorkbookToTransportFormat(mondayWorkbook: XLSX.WorkBook) {
  const characteristics = getMondayWorkbookCharacteristics(mondayWorkbook);

  if (!characteristics.hasMondayLogo && !characteristics.columnHeadlineIsGrey) {
    throw new Error('This is not a file format that can be converted');
  }

  const firstGroupRowIdx = characteristics.hasBoardDescription ? 3 : 1;
  const mondaySheet = mondayWorkbook.Sheets[mondayWorkbook.SheetNames[0]];

  const transportFormat: BoardTransportFormat = {
    name: getBoardName(mondayWorkbook),
    headlines: getRowValues(mondaySheet, 0, firstGroupRowIdx + 1),
    subitemsHeadlines: null,
    items: [],
    subitems: null,
  };

  const range = XLSX.utils.decode_range(mondaySheet['!ref']!);
  const rowsCount = range.e.r + 1;
  let currentGroup = null;

  for (let i = firstGroupRowIdx; i <= rowsCount; i++) {
    const firstCell = mondaySheet[XLSX.utils.encode_cell({ c: 0, r: i })];
    const secondCell = mondaySheet[XLSX.utils.encode_cell({ c: 1, r: i })];
    const prevPrevFirstCell = mondaySheet[XLSX.utils.encode_cell({ c: 0, r: i - 2 })];
    const prevFirstCell = mondaySheet[XLSX.utils.encode_cell({ c: 0, r: i - 1 })];
    const nextFirstCell = mondaySheet[XLSX.utils.encode_cell({ c: 0, r: i + 1 })];

    if (!firstCell) {
      continue;
    }

    if (i === firstGroupRowIdx || !prevFirstCell) {
      // group headline
      currentGroup = firstCell.v;
      continue;
    }

    if (i === firstGroupRowIdx + 1) {
      // the item headlines of the first group
      continue;
    }

    if (!prevPrevFirstCell) {
      // item headlines
      continue;
    }

    if (hasFormulasInRow(mondaySheet, i)) {
      // it's a row with summary formulas
      continue;
    }

    if (firstCell.v === 'Subitems' && nextFirstCell && nextFirstCell.v === '' && !hasFormulasInRow(mondaySheet, i + 1)) {
      // subitems headlines
      if (!transportFormat.subitemsHeadlines) {
        transportFormat.subitemsHeadlines = getRowValues(mondaySheet, 1, i);
      }

      continue;
    }

    if (firstCell.v !== '') {
      // item
      transportFormat.items.push(rowToItemTransport(mondaySheet, currentGroup, 0, i, transportFormat.headlines.length));
      continue;
    }

    if (firstCell.v === '' && secondCell && secondCell.v !== '') {
      // subitem
      const subitem = {
        ...rowToItemTransport(mondaySheet, null, 1, i, transportFormat.subitemsHeadlines!.length),
        parentIdx: transportFormat.items.length - 1,
      };
      if (!transportFormat.subitems) {
        transportFormat.subitems = [];
      }

      transportFormat.subitems.push(subitem);
      continue;
    }
  }

  return transportFormat;
}

function formatExcelCols(json: any[][], maxWidth = 80, minWidth = 10) {
  if (!json.length) {
    return [];
  }

  let widthArr = Object.keys(json[0]).map((key) => {
    return { width: Math.max(key.length + 2, minWidth) }; // plus 2 to account for short object keys
  });

  for (let i = 0; i < json.length; i++) {
    let value = Object.values(json[i]);

    for (let j = 0; j < value.length; j++) {
      if (!widthArr[j]) {
        widthArr[j] = {
          width: minWidth,
        };
      }

      if (value[j] !== null && `${value[j]}`.length > widthArr[j].width) {
        widthArr[j].width = Math.min(`${value[j]}`.length, maxWidth);
      }
    }
  }

  return widthArr;
}

function headlinesToCells(headlines: string[]) {
  return headlines.map((headline) => {
    return { v: headline, t: 's', s: { font: { bold: true, name: 'Arial' } } };
  });
}

function formatRow(row: any[]) {
  return row.map((cell) => {
    return { ...cell, s: { font: { name: 'Arial' } } };
  });
}

export function transportFormatToWorkbook(
  transportFormat: BoardTransportFormat,
  includeMainBoard = true,
  includeSubitemsBoard = true,
): XLSX.WorkBook {
  const targetWorkbook = XLSX.utils.book_new();
  const headlineCells = headlinesToCells(transportFormat.headlines.concat(['Group']));
  const targetSheet = XLSX.utils.aoa_to_sheet([headlineCells]);
  let targetSheetSubitems: XLSX.WorkSheet | null = null;

  if (transportFormat.subitemsHeadlines) {
    const subitemHeadlineCells = headlinesToCells(['Parent Item', ...transportFormat.subitemsHeadlines]);
    targetSheetSubitems = XLSX.utils.aoa_to_sheet([subitemHeadlineCells]);
  }

  transportFormat.items.forEach((item, idx) => {
    const row: any[] = [];

    item.cells.forEach((cell) => {
      row.push(_.pick(cell.original, ['v', 't', 'z', 's']));
    });

    row.push({ v: item.group, t: 's' });
    const rowFormatted = formatRow(row);

    XLSX.utils.sheet_add_aoa(targetSheet, [rowFormatted], { origin: -1 });

    if (targetSheetSubitems && transportFormat.subitems) {
      const subitems = transportFormat.subitems.filter((subitem) => subitem.parentIdx === idx);

      subitems.forEach((subitem) => {
        const row: any[] = [];

        row.push({ v: item.name, t: 's' });

        subitem.cells.forEach((cell) => {
          row.push(_.pick(cell.original, ['v', 't', 'z', 's']));
        });

        const rowFormatted = formatRow(row);

        XLSX.utils.sheet_add_aoa(targetSheetSubitems!, [rowFormatted], { origin: -1 });
      });
    }
  });

  const mainSheetName =
    transportFormat.name
      ?.trim()
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .substring(0, 20) || 'Sheet1';

  if (includeMainBoard) {
    try {
      targetSheet['!cols'] = formatExcelCols(XLSX.utils.sheet_to_json(targetSheet));
    } catch (err) {}

    targetWorkbook.SheetNames.push(mainSheetName);
    targetWorkbook.Sheets[mainSheetName] = targetSheet;
  }

  if (targetSheetSubitems && includeSubitemsBoard) {
    try {
      targetSheetSubitems['!cols'] = formatExcelCols(XLSX.utils.sheet_to_json(targetSheetSubitems));
    } catch (err) {}

    targetWorkbook.SheetNames.push(mainSheetName + ' Subitems');
    targetWorkbook.Sheets[mainSheetName + ' Subitems'] = targetSheetSubitems;
  }

  return targetWorkbook;
}

export async function fileToWorkbook(file: any): Promise<XLSX.WorkBook> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = (e) => {
      try {
        const data = (e.target as any).result;
        const workbook = XLSX.read(data, { type: 'binary', cellStyles: true, bookFiles: true });
        resolve(workbook);
      } catch (err) {
        reject(err);
      }
    };

    reader.onerror = (err) => {
      reject(err);
    };
  });
}
