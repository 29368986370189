import { Flex, Box } from 'monday-ui-react-core';

type ErrorBoxProps = {
  message: string;
};

function ErrorBox({ message }: ErrorBoxProps) {
  return (
    <div
      style={{
        backgroundColor: '#d83a52',
        color: '#ffffff',
      }}
    >
      <Box padding={Box.paddings?.LARGE as any} paddingStart={Box.paddingStarts?.XL as any} paddingEnd={Box.paddingEnds?.XL as any}>
        <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Oops, something went wrong: {message}
          </div>
        </Flex>
      </Box>
    </div>
  );
}

export default ErrorBox;
