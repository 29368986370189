import useSWR from 'swr';
import { getMe } from '../services/backend';

export function useMe() {
  const { data: user, error } = useSWR(
    `/me`,
    async () => {
      const user = await getMe();

      if (user.error) {
        throw new Error(user.error);
      }

      return user;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    user,
    error,
  };
}
