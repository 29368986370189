import { useState } from 'react';
import * as XLSX from 'xlsx';
import { fileToWorkbook } from '../services/converter';
import { Heading, Avatar } from 'monday-ui-react-core';
// @ts-ignore-next-line
import FileIcon from 'monday-ui-react-core/dist/icons/File';
// @ts-ignore-next-line
import CheckIcon from 'monday-ui-react-core/dist/icons/Check';

type FilePickerProps = {
  format: boolean | null;
  onChange: (workbook: XLSX.WorkBook | null) => void;
};

function SpreadsheetPicker({ format, onChange }: FilePickerProps) {
  const [file, setFile] = useState<string | undefined>(undefined);

  return (
    <div className="SpreadsheetPicker-Wrapper">
      <label className="SpreadsheetPicker-Label" htmlFor="monday_excel">
        <div className="SpreadsheetPicker-Avatar">
          <Avatar
            size={Avatar.sizes?.LARGE}
            type={Avatar.types?.ICON}
            icon={format ? CheckIcon : FileIcon}
            customBackgroundColor="#0073ea"
            withoutBorder
            square
          />
        </div>
        <div className="SpreadsheetPicker-Headline">
          {format ? (
            <>
              <Heading type={Heading.types?.h4 as any} value={file} customColor="#0073ea" />
              <span className="SpreadsheetPicker-Text">
                Click the file format below into which you <br />
                want to convert the selected Excel file.
              </span>
            </>
          ) : (
            <>
              <Heading type={Heading.types?.h4 as any} value="Choose file" customColor="#0073ea" />
              <span className="SpreadsheetPicker-Text">
                Select your exported Excel file <br />
                from monday.com to convert.
              </span>
            </>
          )}
        </div>
      </label>
      {/* 
      {format ? (
        <div className="SpreadsheetPicker-Button">
          <Button
            kind={Button.kinds?.SECONDARY}
            onClick={() => {
              setFile(undefined);
            }}
          >
            Remove
          </Button>
        </div>
      ) : null}
*/}
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 0,
          height: 0,
          opacity: 0,
        }}
      >
        <input
          id="monday_excel"
          type="file"
          accept=".xlsx"
          onChange={async (ev) => {
            if (ev.target.files) {
              const workbook = await fileToWorkbook(ev.target.files[0]);
              onChange(workbook);
              setFile(ev.target.files[0].name);
            }
          }}
        />
      </div>
    </div>
  );
}

export default SpreadsheetPicker;
