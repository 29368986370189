import ConverterWidget from './components/ConverterWidget';

function App() {
  return (
    <div>
      <ConverterWidget />
    </div>
  );
}

export default App;
